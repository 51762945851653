<template>
  <v-card>
    <v-card-title>
      {{ mode === 'edit' ? 'Editar' : 'Adicionar' }}
    </v-card-title>

    <v-container v-if="mode === 'edit'">
      <v-card outlined>
        <v-col>
          <v-text-field
            v-model="modelsData.vehicle_model.vehicle_model"
            :rules="rolesRequired"
            :items="listVehicles"
            readonly
            label="Modelo"
            outlined
            dense
          />
          <v-text-field
            v-model="modelsData.percentage_comission"
            label="% comissão"
            outlined
            dense
          />
          <v-select
            v-model="modelsData.type"
            :items="options"
            label="Tipo"
            outlined
            dense
          />
        </v-col>
      </v-card>
      <v-col class="d-flex flex-row-reverse">
        <v-btn
          color="info"
          @click="updateModelsById()"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-container>

    <v-container v-else>
      <v-card outlined>
        <v-col>
          <v-autocomplete
            v-model="dataVehicle.name"
            :rules="rolesRequired"
            :items="listVehicles"
            label="Modelo"
            outlined
            dense
          />
          <v-text-field
            v-model="addDataModel.percentage_comission"
            label="Percentual"
            outlined
            dense
          />
          <v-select
            v-model="addDataModel.type"
            :items="options"
            label="Tipo"
            outlined
            dense
          />
        </v-col>
      </v-card>
      <v-col class="d-flex flex-row-reverse">
        <v-btn
          color="info"
          @click="addModels()"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-container>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'

export default {
  props: {
    modelsData: {
      type: Object,
      required: true,
      default: () => {},
    },
    mode: {
      type: String,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    updatedTable: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      rolesRequired: [r => !!r || 'Campo obrigatório'],
      addDataModel: {
        vehicle_model: '',
        percentage_comission: '',
        type: '',
      },
      options: ['C', 'B'],
      listVehiclesItems: [],
      dataVehicle: {
        name: '',
        id: '',
      },
    }
  },

  computed: {
    listVehicles() {
      const vehicles = []

      this.listVehiclesItems.map(items => {
        items.map(item => {
          vehicles.push(item.vehicle_model)
        })
      })

      return vehicles
    },
  },

  created() {
    this.getItemsVehicles()
  },

  methods: {
    async getItemsVehicles() {
      await axiosIns.get('api/v1/integration/vehicle_model/index')
        .then(res => {
          this.listVehiclesItems.push(res.data.data)
        })
    },
    async updateModelsById() {
      const dataBody = {
        percentage_comission: this.modelsData.percentage_comission,
        type: this.modelsData.type,
        vehicle_models_id: this.modelsData.vehicle_models_id,
      }
      await axiosIns.put(`api/v1/integration/cnh_bonus_model/update/${this.modelsData.id}`, dataBody)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados da comissão atualizados!',
            showConfirmButton: false,
            timer: 2000,
          })

          this.closeModal()
          this.updatedTable()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao atualizar dados!',
            showConfirmButton: false,
            text: error,
          })

          this.closeModal()
          this.updatedTable()
        })
    },

    async addModels() {
      const idVehicle = []

      this.listVehiclesItems.map(items => {
        items.map(item => {
          // eslint-disable-next-line no-unused-expressions
          item.vehicle_model === this.dataVehicle.name ? idVehicle.push(item.id) : null
        })
      })

      const dataModel = {
        vehicle_models_id: idVehicle[0],
        percentage_comission: this.addDataModel.percentage_comission,
        type: this.addDataModel.type,
      }

      await axiosIns.post('api/v1/integration/cnh_bonus_model/store/', dataModel)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Dados da comissão salvos!',
            showConfirmButton: false,
            timer: 2000,
          })

          this.addDataModel = {
            percentage_comission: '',
            percentage_bonus: '',
          }
          this.dataVehicle = {
            name: '',
          }
          this.closeModal()
          this.updatedTable()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao adicionar dados!',
            showConfirmButton: false,
            text: error,
            timer: 2000,
          })
          this.closeModal()
        })
    },
  },

}
</script>
