<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        <div class="mr-auto p-2 bd-highlight">
          % Fixo da comissão por modelo
        </div>
        <v-btn
          color="info"
          class="p-2 bd-highlight"
          @click="openModalAddModel()"
        >
          Adicionar
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="dataTable"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
        hide-default-footer
      >
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.id === loadingSpinner ? false : true"
            medium
            class="me-2"
            @click="getItemBonusById(item.id)"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.id === loadingSpinner ? true : false"
            indeterminate
            :size="25"
            color="gray"
          >
          </v-progress-circular>
          <v-icon
            medium
            color="error"
            class="me-2"
            @click="openModalDeleteModelCommission(item.id)"
          >
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
      </v-data-table>
      <v-card-text>
        <v-row>
          <v-col>
            <v-pagination
              v-model="pageOptions.page"
              total-visible="5"
              :length="pageOptions.pageCount"
              @input="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="openDeleteModal"
      max-width="290"
    >
      <v-card>
        <v-container>
          <v-card-title class="text-h5">
            Excluindo comissão
          </v-card-title>
          <v-card-text>Você tem certeza que deseja excluir esta comissão</v-card-text>
          <v-card-text>Esta ação não poderá ser revertida!</v-card-text>
          <v-card-actions class="d-flex flex-row-reverse bd-highlight">
            <v-btn
              color="error"
              @click="deleteCommissionConfig()"
            >
              Excluir
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="openModal"
      width="500"
    >
      <modal-model
        :models-data="dataToModalById"
        :mode="mode"
        :close-modal="closeModal"
        :updated-table="updatedTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiDeleteOutline, mdiPencilOutline } from '@mdi/js'
import modalModel from './modalModels.vue'

export default {
  components: {
    modalModel,
  },
  data() {
    return {
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },

      dataToModalById: [],
      mode: '',
      loadingSpinner: '',
      loading: true,
      openModal: false,
      dataTable: [],
      headers: [
        { text: 'MODELO', value: 'vehicle_model.vehicle_model', align: 'center' },
        { text: 'PERCENTUAL', value: 'percentage_comission', align: 'center' },
        { text: 'TIPO', value: 'type', align: 'center' },
        { text: 'AÇÕES', value: 'actions', align: 'center' },

      ],
      icons: {
        mdiPencilOutline,
        mdiDeleteOutline,
      },
      openDeleteModal: false,
      idToDeleteModelCommission: '',
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      await axiosIns.get('api/v1/integration/cnh_bonus_model/index')
        .then(res => {
          this.dataTable = res.data.data
          this.loading = false
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
        })
    },

    async getItemBonusById(modelId) {
      this.loadingSpinner = modelId
      await axiosIns.get(`api/v1/integration/cnh_bonus_model/edit/${modelId}`)
        .then(res => {
          this.dataToModalById = res.data.data
          this.openModal = true
        })
      this.loadingSpinner = ''
      this.mode = 'edit'
    },
    closeModal() {
      this.openModal = false
    },
    openModalAddModel() {
      this.mode = 'add'
      this.openModal = true
    },

    async nextPage() {
      this.loading = true
      await axiosIns.get(`api/v1/integration/cnh_bonus_model/index?page=${this.pageOptions.page}`)
        .then(res => {
          this.dataTable = res.data.data
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.loading = false
        })
    },

    async updatedTable() {
      await this.getItemsTable()
    },

    async deleteCommissionConfig() {
      await axiosIns.delete(`api/v1/integration/cnh_bonus_model/destroy/${this.idToDeleteModelCommission}`)
      this.updatedTable()
      this.openDeleteModal = false
    },

    openModalDeleteModelCommission(item) {
      this.openDeleteModal = true
      this.idToDeleteModelCommission = item
    },

  },
}
</script>
